import React from 'react';
import { FiTwitter, FiGithub, FiLinkedin, FiMail, FiPhone, FiMessageSquare } from 'react-icons/fi';
import './footer.css';
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const message = `Hello Arun! I visited your portfolio and I'd like to connect with you for a project.`;
  const whatsappURL = `https://api.whatsapp.com/send?phone=918075648949&text=${encodeURIComponent(message)}`;

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__content">
          <div className="footer__section">
            <h3 className="footer__title">Contact Me</h3>
            <div className="footer__contact">
              <a href="mailto:arunknair2003@gmail.com" className="footer__link">
                <FiMail className="footer__icon" /> Email
              </a>
              <a href="tel:+918075648949" className="footer__link">
                <FiPhone className="footer__icon" /> Call
              </a>
              <a href={whatsappURL} className="footer__link">
                <FiMessageSquare className="footer__icon" /> WhatsApp
              </a>
            </div>
          </div>
          <div className="footer__section">
            <h3 className="footer__title">Connect</h3>
            <div className="footer__social">

              <a href="https://twitter.com/ArunKNa94162991" className="footer__social-link">
                <FiTwitter />&nbsp; Twitter
              </a>
              <a href="https://github.com/AKN414-IND" className="footer__social-link">
                <FiGithub />&nbsp; Github
              </a>
              <a href="https://www.linkedin.com/in/arunknair-/" className="footer__social-link">
                <FiLinkedin />&nbsp; LinkedIn
              </a>
            </div>
          </div>
        </div>
        <div className="footer__bottom">
          <p className="footer__copy">&copy; {currentYear} Arun K Nair. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;