import './App.css';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Loader from './Loader/Loader.js';

const Main = lazy(() => import('./Main.jsx'));
const ProjectList = lazy(() => import('./components/projects/ProjectList'));
const ProjectDetails = lazy(() => import('./components/projects/ProjectDetails'));

const App = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5800);

        return () => clearTimeout(timer);
    }, []);

    return (
        <BrowserRouter>
            <Helmet>
                <title>Arun K Nair - Web Developer Portfolio</title>
                <meta name="description" content="Explore the projects and skills of Arun K Nair, a professional web developer specializing in React applications." />
            </Helmet>
            
            
            <main className='main'>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="/" element={isLoading ? <Loader /> : <><Header /><Main /><Footer /></>} />
                        <Route path="/projects" element={<><Header /><ProjectList /> <Footer /></>} />
                        <Route path="/projects/:id" element={<><Header /><ProjectDetails /><Footer /></>} />
                    </Routes>
                </Suspense>
            </main>
            
        </BrowserRouter>
    );
};

export default App;