import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Terminal, Loader2 } from 'lucide-react';
import './Loader.css';

const PortfolioLoader = () => {
  const [text, setText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const [os, setOS] = useState('Unknown OS');
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    const detectOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      const platform = window.navigator.platform.toLowerCase();
      
      if (/android/.test(userAgent)) return "Android";
      if (/iphone|ipad|ipod/.test(userAgent)) return "iOS";
      if (/win/.test(platform)) return "Windows";
      if (/mac/.test(platform)) return "macOS";
      if (/linux/.test(platform)) return "Linux";
      return "Unknown OS";
    };
    setOS(detectOS());
  }, []);

  const getOSSpecificCode = useCallback(() => {
    const commonCommands = `
  > load Arun K Nair's portfolio!
  
  loading Arun K Nair's portfolio...
  
  > cd portfolio && npm start
  
  Starting the React.js development server...
  
  Compiled successfully!
  
  Arun's portfolio is now hosted and you can view it at:
    Domain:            https://arunknair.me
  `;
  
    return commonCommands;
  }, []);


  const fullText = getOSSpecificCode();

  const typeText = useCallback(() => {
    if (text.length < fullText.length) {
      setText(fullText.slice(0, text.length + 1));
    } else {
      setIsTyping(false);
      setTimeout(() => setShowReload(true), 1000);
    }
  }, [text, fullText]);

  useEffect(() => {
    if (isTyping) {
      const timeout = setTimeout(typeText, 10);  // Increased speed slightly
      return () => clearTimeout(timeout);
    }
  }, [isTyping, typeText]);

  const handleClick = () => {
    if (!isTyping) {
      setText('');
      setIsTyping(true);
      setShowReload(false);
    }
  };

  return (
    <div className="loader-container">
      <motion.div 
        className="loader-content"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="loader-header">
          <Terminal className="loader-icon" size={24} />
          <h2 className="loader-title">{os} Terminal</h2>
        </div>
        <div className="terminal-window">
          <pre className="terminal-text">
            <code>{text}</code>
          </pre>
        </div>
        <AnimatePresence>
          {isTyping && (
            <motion.div 
              className="loader-spinner"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Loader2 className="spinner-icon" size={24} />
            </motion.div>
          )}
          {showReload && (
            <motion.button
              className="reload-button"
              onClick={handleClick}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Reload Terminal
            </motion.button>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default PortfolioLoader;